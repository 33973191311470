// import FontFaceObserver from 'fontfaceobserver';
import { ICON_SPRITE_URL } from './constants';

document.documentElement.className += ' has-optimized-font has-fonts';

// function fontLoader() {
//   // Optimization for Repeat Views
//   if (sessionStorage.fontsLoadedCriticalFoftPreloadPolyfillSTKI) {
//     document.documentElement.className += ' has-optimized-font has-fonts';
//     return;
//   }

//   const fontPrimaryOptimized = new FontFaceObserver('Fira Sans Optimized');

//   Promise.all([fontPrimaryOptimized.load(null, 10000)]).then(() => {
//     document.documentElement.className += ' has-optimized-font';

//     const fontPrimary = new FontFaceObserver('Fira Sans');
//     const fontPrimaryLight = new FontFaceObserver('Fira Sans', { weight: 300 });
//     const fontPrimaryBold = new FontFaceObserver('Fira Sans', { weight: 700 });
//     const fontSecondary = new FontFaceObserver('Barlow Condensed', { weight: 300 });
//     const fontSecondaryBold = new FontFaceObserver('Barlow Condensed', { weight: 600 });

//     Promise.all([
//       fontPrimary.load(null, 10000),
//       fontPrimaryLight.load(null, 10000),
//       fontPrimaryBold.load(null, 10000),
//       fontSecondary.load(null, 10000),
//       fontSecondaryBold.load(null, 10000),
//     ]).then(() => {
//       document.documentElement.className += ' has-optimized-font has-fonts';

//       // Optimization for Repeat Views
//       sessionStorage.fontsLoadedCriticalFoftPreloadPolyfillSTKI = true;
//     });
//   });
// }

// fontLoader();

/**
 * Inject icons
 */
const request = new XMLHttpRequest();
request.open('GET', ICON_SPRITE_URL, true);
request.onreadystatechange = () => {
  if (request.readyState === 4) {
    const div = document.createElement('div');
    const ready = () => document.body.appendChild(div);

    div.setAttribute('style', 'height: 0; width: 0; position: absolute; visibility: hidden; display: none');
    div.innerHTML = request.responseText;

    if (document.readyState === 'complete') {
      ready();
    } else {
      document.addEventListener('DOMContentLoaded', () => ready(), false);
      window.addEventListener('load', () => ready(), false);
    }
  }
};
request.send();
